import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import TokenIcon from '../../../../components/TokenIcon';
import { tokenColors } from '../../../../utils/tokenIcons';
import formatCurrency from '../../../../utils/currencyFormatter';

const Wallet = ({ wallet, selectedWallet, setSelectedWallet }) => {
  const [isTokensOpened, setIsTokensOpened] = useState(true);
  const [hasTokens, setHasTokens] = useState(false);
  const [walletTokens, setWalletTokens] = useState([]);
  const isSelected =
    selectedWallet && !selectedWallet.hasOwnProperty('holdBalance') && wallet._id === selectedWallet._id;

  useEffect(() => {
    const filtered = wallet.tokens.filter(token => token.balance.usd > 0);
    setHasTokens(!!filtered.length);
    setWalletTokens(filtered);
  }, [wallet]);

  useEffect(() => {
    if (
      !isTokensOpened &&
      selectedWallet &&
      selectedWallet.hasOwnProperty('holdBalance') &&
      selectedWallet.address === wallet.address
    ) {
      setSelectedWallet(null);
    }
  }, [isTokensOpened]);

  return (
    <div
      className={`fee-wallets-list__wallet ${hasTokens ? 'fee-wallets-list__wallet_hasTokens' : ''}`}
      onMouseEnter={e => (e.currentTarget.style.borderColor = tokenColors[wallet.currency]?.color)}
      onMouseLeave={e =>
        (e.currentTarget.style.borderColor = (!hasTokens || !isTokensOpened) && isSelected ? 'transparent' : '#E0E6ED')
      }
      style={
        (!hasTokens || !isTokensOpened) && isSelected
          ? {
              background: tokenColors[wallet.currency]?.bgColor || 'transparent',
              borderColor: 'transparent',
            }
          : {
              background: 'transparent',
              borderColor: '#E0E6ED',
            }
      }
    >
      {hasTokens ? (
        <div
          className={`fee-wallets-list__dropdown-icon ${
            isTokensOpened ? 'fee-wallets-list__dropdown-icon_opened' : ''
          }`}
          onClick={() => setIsTokensOpened(!isTokensOpened)}
        >
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 6.12598L8 10.126L12 6.12598"
              stroke={tokenColors[wallet.currency]?.color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ) : (
        ''
      )}
      <Row
        align="middle"
        onClick={() => {
          setSelectedWallet(wallet);
        }}
        style={
          hasTokens && isTokensOpened && isSelected
            ? {
                background: tokenColors[wallet.currency]?.bgColor || 'transparent',
                borderColor: 'transparent',
              }
            : {
                background: 'transparent',
                borderColor: '#E0E6ED',
              }
        }
      >
        <Col span={1}>
          <TokenIcon tokenName={wallet.currency} className="fee-wallets-list__logo" />
        </Col>
        <Col span={13}>
          <div className="fee-wallets-list__address" style={{ color: tokenColors[wallet.currency]?.color }}>
            {wallet.address}
          </div>
        </Col>
        <Col span={10}>
          <div className="fee-wallets-list__balance">
            <div className="fee-wallets-list__balance-token">
              {wallet.balance.value} {wallet.currency} {' '}

              {wallet.currencyType === 'token' && wallet.nodeType === 'optimism' ? wallet.currency === 'OP' ? null : 'OP' : null } {' '}

              {wallet.currency === 'ETH' && wallet.currencyType === 'currency' && wallet.nodeType === 'optimism' ?  'OP' : null}


              {wallet.currencyType === 'currency' && wallet.nodeType === 'arbitrum' ? wallet.name === 'arbitrum' ? null : 'ARB' : null}
            </div>
            <div className="fee-wallets-list__balance-usd">{formatCurrency(wallet.balance.usd)}</div>
          </div>
        </Col>
      </Row>
      {isTokensOpened && (
        <div className="fee-wallets-list__tokens-dropdown">
          {walletTokens.map(token => {
            const isTokenSelected =
              selectedWallet && selectedWallet.currencyId === token.currencyId && wallet._id === selectedWallet._id;
            return (
              <Row
                key={token.currencyId}
                align="middle"
                className="fee-wallets-list__token"
                onClick={() => {
                  setSelectedWallet({ ...token, _id: wallet._id, address: wallet.address });
                }}
                style={{ background: isTokenSelected ? tokenColors[token.currency]?.bgColor : 'transparent' }}
              >
                <Col span={1}>
                  <TokenIcon tokenName={token.currency} className="fee-wallets-list__logo" />
                </Col>
                <Col span={13}>
                  <div className="fee-wallets-list__address" style={{ color: tokenColors[token.currency]?.color }}>
                    {wallet.address}
                  </div>
                </Col>
                <Col span={10}>
                  <div className="fee-wallets-list__balance">
                    <div className="fee-wallets-list__balance-token">
                      {token.balance.value} {token.currency}
                    </div>
                    <div className="fee-wallets-list__balance-usd">{formatCurrency(token.balance.usd)}</div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Wallet;
